<template>
  <v-card height="100%">
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-checkbox-blank-outline</v-icon>
      Usuários que não fizeram checkin
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="!loading">
      <div class="text-description flex row justify-center q-pl-md">
        Total de usuários: {{ totalDados }}
      </div>
      <Bar
        :chartData="graficoData"
        :chartOptions="chartOptions"
        :styles="styles"
      />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhuma Pessoa Cadastrada
    </v-card-text>
  </v-card>
</template>

<script>
import { noShow } from "@/api/admin/graficos/usuarios.js";

export default {
  name: "BoxTotalPosts",

  components: {
    Bar: () => import("@/views/home/graficos/Bar.vue"),
  },

  data() {
    return {
      graficoData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
      },
      styles: {
        width: "100%",
        marginTop: "8px",
      },
      loading: false,
      totalDados: 0,
    };
  },

  methods: {
    async getGruposRenda() {
      this.loading = true;
      try {
        const response = await noShow();
        const colors = ["#FFE082", "#a3a3a3", "#ff8383"];
        this.totalDados = response.total;
        this.graficoData = {
          labels: ["Usuários"],
          datasets: response.data.map((item, index) => {
            return {
              label: item.tipo,
              data: [item.total],
              backgroundColor: colors[index],
            };
          }),
        };

        this.chartOptions = {
          responsive: true,
          plugins: {
            dataLabels: {
              display: true,
              color: "black",
            },
            legend: {
              position: "bottom",
            },
          },
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    reloadGet() {
      this.graficoData = {
        labels: [],
        datasets: [],
      };
      this.getGruposRenda();
    },
  },

  mounted() {
    this.getGruposRenda();
  },
};
</script>
<style></style>
